<template>
  <div class="h-full">
    <div class="bg-mask" v-if="isShowBack"></div>
    <div class="bg-circles" v-if="isShowBack"></div>
    <div class="back-btn-wrapper" v-if="isShowBack">
      <button class="back-btn" v-if="this.$route.name !== 'successRequest'"  @click="goBack()">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="43" height="43" viewBox="0 0 43 43">
          <defs>
            <filter id="Rectangle_3158" x="0" y="0" width="43" height="43" filterUnits="userSpaceOnUse">
              <feOffset dy="3" input="SourceAlpha"/>
              <feGaussianBlur stdDeviation="3" result="blur"/>
              <feFlood flood-opacity="0.161"/>
              <feComposite operator="in" in2="blur"/>
              <feComposite in="SourceGraphic"/>
            </filter>
          </defs>
          <g id="Group_12961" data-name="Group 12961" transform="translate(-308 -28)">
            <g id="Group_4717" data-name="Group 4717" transform="translate(317 33.547)">
              <g id="Group_4426" data-name="Group 4426" transform="translate(0 0.452)">
                <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#Rectangle_3158)">
                  <rect id="Rectangle_3158-2" data-name="Rectangle 3158" width="25" height="25" rx="12.5" transform="translate(9 6)" fill="#ff7400"/>
                </g>
              </g>
            </g>
            <path id="ic_chevron_right_24px" d="M10,6,8.59,7.41,13.17,12,8.59,16.59,10,18l6-6Z" transform="translate(317.705 34.376)" fill="#fff"/>
          </g>
        </svg>
      </button>
    </div>
    <router-view ></router-view>
  </div>
</template>

<script>
import Step1 from "../../components/customerAd/Step1"
export default {
  name: "AddRequest",
  components : {
    Step1,

  },
  data(){
    return{
      isActive : false,
    }
  },
  methods :{
    goBack(){
      if (this.$route.name === 'AddRequestStep2'){
        this.$router.push({name : 'AddRequestStep1'})
      }else{
        this.$router.go(-1)
      }
    },
  },
  computed :{
    isShowBack(){
      return this.$store.state.general.addEstate.isShowBack
    }
  }

}
</script>

<style scoped>

</style>